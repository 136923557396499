export class ISupervision {
  constructor() {
    this.id = null;
    this.creator = {};
    this.situation = {};
    this.responsible = {};
    this.project = {};
    this.authority = {};
    this.finished = 0;
    this.stage_1 = {
      id: null,
      visit_date: null,
      supervisory_act: null,
      request_for_information: null,
      requirement_compliance_charge: null,
      preventive_measures: null,
      pending_tasks: [],
    };
    this.stage_2 = {
      id: null,
      monitoring_report: null,
      monitoring_report_summary: null,
      pending_tasks: [],
      presentation_date: null,
    };
    this.stage_3 = {
      id: null,
      instance_1: {
        id: null,
        attachment: null,
        receipt_date: null,
        summary_resolution_sanction: null,
      },
      instance_2: {
        id: null,
        release_writing_charge_1: null,
        pending_tasks: [],
      },
      instance_3: {
        id: null,
        release_writing_charge_2: null,
        release_writing_charge_2_disabled: 0,
        pending_tasks: [],
      },
      instance_4: {
        id: null,
        additional_information: null,
        additional_information_disabled: 0,
        pending_tasks: [],
      },
      instance_5: {
        id: null,
        attachment: null,
        receipt_date: null,
        summary_resolution_sanction: null,
      },
      instance_6: {
        id: null,
        release_writing_charge_1: null,
        pending_tasks: [],
      },
      instance_7: {
        id: null,
        first_instance_resolution_attachment: null,
        first_instance_resolution_result: null,
        first_instance_resolution_summary: null,
      },
      instance_8: {
        id: null,
        appeal_fee_disabled: 0,
        appeal_fee: null,
        pending_tasks: [],
      },
      instance_9: {
        id: null,
        date_oral_report: null,
        pending_tasks: [],
        oral_report_notification_disabled: 0,
        oral_report_notification: null,
      },
      instance_10: {
        id: null,
        first_instance_resolution_result: null,
        second_instance_resolution_attachment: null,
        second_instance_resolution_summary: null,
        administrative_contentious_lawsuit: 0,
      },
    };
  }
}
